import React, {lazy, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import './index.scss';
import "./i18n";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {store} from "./redux/store";
import logo from "./assets/images/ARROWFIGHT_registered_logo_only.svg"
import LogRocket from "logrocket";


const LazyApp = lazy(() => {
    LogRocket.init('6jbbck/arrowfightcom');

    return new Promise(resolve => {
        // @ts-ignore
        setTimeout(() => resolve(import('./App')), 300);
    })
})

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Suspense fallback={<div className="suspense_logo">
            <img src={logo} alt="arrowfight"/>
        </div>}>
            <Provider store={store}>
                <BrowserRouter basename="/">
                    <LazyApp style={{backgroundColor: 'black'}}/>
                </BrowserRouter>
            </Provider>
        </Suspense>
    </React.StrictMode>);


// root.render(
//     <React.StrictMode>
//         <Suspense fallback={<div className="suspense_logo">
//             <img src={rocket} alt="arrowhacks rocket" className="image-height-xl"/>
//         </div>}>
//             <Provider store={store}>
//                 <BrowserRouter basename="/">
//                     <LazyApp/>
//                 </BrowserRouter>
//             </Provider>
//         </Suspense>
//     </React.StrictMode>
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
