import {createAsyncThunk, createSlice, Reducer} from '@reduxjs/toolkit'
import axiosAccountService from '../utils/AxiosAccountService';
import {updateObject} from '../utils/utility';
import {AxiosResponse} from "axios";
import {StatisticModel} from "../model/StatisticModel";

const initialState = {
    countOfFighters: undefined,
    loading: false,
    error: false,
};

const getFightersStatRequest = async () => {
    try {
        let response = await axiosAccountService.get<unknown, AxiosResponse<StatisticModel>>('/api/stats/fighters');
        return response.data;
    } catch (error: any) {
        throw error;
    }
};

export const getFightersStatsThunk = createAsyncThunk(
    'stats/fighters/get',
    async () => {
        return await getFightersStatRequest();
    }
)

export const accountSlice = createSlice({
    name: 'stats',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getFightersStatsThunk.pending, (state, action) => {
                return updateObject(state, {loading: true, error: false})
            })
            .addCase(getFightersStatsThunk.fulfilled, (state, action) => {
                return updateObject(state, {
                    countOfFighters: action.payload, loading: false, error: false
                });
            })
            .addCase(getFightersStatsThunk.rejected, (state, action) => {
                return updateObject(state, {loading: false, error: true})
            })
    }
})
export const accountReducer: Reducer<typeof initialState> = accountSlice.reducer;
