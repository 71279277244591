    import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {settingReducer} from "./settingSlice";
import {accountReducer} from "./accountSlice";
    import {zoneReducer} from "./zoneSlice";

const reducer = combineReducers({
    account: accountReducer,
    zone: zoneReducer,
    setting: settingReducer,
})
export const store = configureStore({
    reducer
})

export default function configureAppStore() {
    return configureStore({
        reducer
    })
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof configureAppStore>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAccountSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useZoneSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useSettingSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAccountDispatch = () => useDispatch<AppDispatch>()
export const useZoneDispatch = () => useDispatch<AppDispatch>()