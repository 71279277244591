import {createAsyncThunk, createSlice, Reducer} from '@reduxjs/toolkit'
import axiosZoneService from '../utils/AxiosZoneService';
import {updateObject} from '../utils/utility';
import {AxiosResponse} from "axios";
import {StatisticModel} from "../model/StatisticModel";

const initialState = {
    countOfFights: undefined,
    countOfBattleFights: undefined,
    countOfTrainings: undefined,
    countOfBattles: undefined,
    loading: false,
    error: false,
};

const getFightsStatRequest = async () => {
    try {
        let response = await axiosZoneService.get<unknown, AxiosResponse<StatisticModel>>('/api/stats/fights');
        return response.data;
    } catch (error: any) {
        throw error;
    }
};
const getBattleFightsStatRequest = async () => {
    try {
        let response = await axiosZoneService.get<unknown, AxiosResponse<StatisticModel>>('/api/stats/battlefights');
        return response.data;
    } catch (error: any) {
        throw error;
    }
};
const getTrainingStatRequest = async () => {
    try {
        let response = await axiosZoneService.get<unknown, AxiosResponse<StatisticModel>>('/api/stats/trainings');
        return response.data;
    } catch (error: any) {
        throw error;
    }
};
const getBattleStatRequest = async () => {
    try {
        let response = await axiosZoneService.get<unknown, AxiosResponse<StatisticModel>>('/api/stats/battles');
        return response.data;
    } catch (error: any) {
        throw error;
    }
};

export const getFightsStatsThunk = createAsyncThunk(
    'stats/fights/get',
    async () => {
        return await getFightsStatRequest();
    }
)
export const getBattleFightsStatsThunk = createAsyncThunk(
    'stats/battlefights/get',
    async () => {
        return await getBattleFightsStatRequest();
    }
)
export const getTrainingStatsThunk = createAsyncThunk(
    'stats/trainings/get',
    async () => {
        return await getTrainingStatRequest();
    }
)
export const getBattleStatsThunk = createAsyncThunk(
    'stats/battles/get',
    async () => {
        return await getBattleStatRequest();
    }
)

export const accountSlice = createSlice({
    name: 'stats',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getFightsStatsThunk.pending, (state, action) => {
                return updateObject(state, {loading: true, error: false})
            })
            .addCase(getFightsStatsThunk.fulfilled, (state, action) => {
                return updateObject(state, {
                    countOfFights: action.payload, loading: false, error: false
                });
            })
            .addCase(getFightsStatsThunk.rejected, (state, action) => {
                return updateObject(state, {loading: false, error: true})
            })

            .addCase(getBattleFightsStatsThunk.pending, (state, action) => {
                return updateObject(state, {loading: true, error: false})
            })
            .addCase(getBattleFightsStatsThunk.fulfilled, (state, action) => {
                return updateObject(state, {
                    countOfBattleFights: action.payload, loading: false, error: false
                });
            })
            .addCase(getBattleFightsStatsThunk.rejected, (state, action) => {
                return updateObject(state, {loading: false, error: true})
            })

            .addCase(getTrainingStatsThunk.pending, (state, action) => {
                return updateObject(state, {loading: true, error: false})
            })
            .addCase(getTrainingStatsThunk.fulfilled, (state, action) => {
                return updateObject(state, {
                    countOfTrainings: action.payload, loading: false, error: false
                });
            })
            .addCase(getTrainingStatsThunk.rejected, (state, action) => {
                return updateObject(state, {loading: false, error: true})
            })

            .addCase(getBattleStatsThunk.pending, (state, action) => {
                return updateObject(state, {loading: true, error: false})
            })
            .addCase(getBattleStatsThunk.fulfilled, (state, action) => {
                return updateObject(state, {
                    countOfBattles: action.payload, loading: false, error: false
                });
            })
            .addCase(getBattleStatsThunk.rejected, (state, action) => {
                return updateObject(state, {loading: false, error: true})
            })
    }
})
export const zoneReducer: Reducer<typeof initialState> = accountSlice.reducer;
