
export const updateObject = (oldObject: any, updatedValues: any) => {
    return {
        ...oldObject,
        ...updatedValues
    };
}
export function getTargetFaceUrl(targetFaceFilename: string) {
    return `https://res.cloudinary.com/arrowhacks/image/upload/c_scale,h_100/v1603233969/arrowfight/targetfaces/${targetFaceFilename}_with_logo.svg`
}

export function getNoticationFadeTimeout() {
    return 5000;
}

export function getRandomNumber() {
    return Math.floor(Math.random() * 10000000000000001);
}
